<template>
  <!--====== APPIE SERVICES PART START ======-->

  <section class="appie-service-area pb-100" id="service">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">
              {{ section_heading }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center pt-100">
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/advantage_one.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
      <div
        class="d-flex flex-col align-items-center"
        style="max-width: 800px; padding-left: 80px"
      >
        <div class="appie-traffic-title">
          <p style="margin-top: 30px; font-size: 24px; line-height: 1.5">
            We Work with a Variety of OEM’s of Electric Vehicles Depending on
            the Specification of End Cliend.
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center"
      style="padding: 20px 0 20px"
    >
      <div class="d-flex flex-col align-items-center" style="max-width: 800px">
        <div class="appie-traffic-title">
          <p style="margin-top: 30px; font-size: 24px; line-height: 1.5">
            We Facilitate and Connect to Financiers in Indonesia or in Singapore
            as Package to Roll-out Large Fleets when Required.
          </p>
        </div>
      </div>
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/advantage_two.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center"
      style="padding: 20px 0 20px"
    >
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/advantage_three.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
      <div
        class="d-flex flex-col align-items-center"
        style="max-width: 800px; padding-left: 80px"
      >
        <div class="appie-traffic-title">
          <p style="margin-top: 30px; font-size: 24px; line-height: 1.5">
            We Utilize Supply Chain Financing for a Greener, Efficient Process
            Flow of the Buying Cycle.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {
  props: {
    section_heading: {
      type: String,
    },
    more_heading: {
      type: String,
    },
    description: {
      type: String,
    },
    grid_items: {
      type: Array,
    },
  },
};
</script>

<style></style>
