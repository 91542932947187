<template>
  <!--====== APPIE SERVICES PART START ======-->

  <section class="appie-service-area pt-90 pb-100" id="service">
    <div class="d-flex flex-row justify-content-between">
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/scf_msg.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
      <div
        class="d-flex flex-col align-items-center"
        style="max-width: 800px; padding-left: 30px"
      >
        <div class="appie-traffic-title">
          <h3 class="title">One platform to gather All party transactions.</h3>
          <p style="margin-top: 30px">
            On-board suppliers, buyers & financiers into one platform for
            removing complexity in typical LC or trade financing deals.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {
  props: {
    section_heading: {
      type: String,
    },
    more_heading: {
      type: String,
    },
    description: {
      type: String,
    },
    grid_items: {
      type: Array,
    },
  },
};
</script>

<style></style>
