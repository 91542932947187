<template>
  <section
    class="appie-testimonial-area pb-100"
    id="testimonial"
    style="margin-top: 100px; margin-bottom: 100px"
  >
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="appie-section-title text-center">
          <h3 class="appie-title">Trusted by Co-Op and Others Entities.</h3>
        </div>
      </div>
    </div>
    <div
      class="container"
      style="
        background-color: white;
        padding: 80px 0 80px;
        max-width: 600px;
        box-shadow: 0px 6px 10px 0px rgba(16, 73, 189, 0.3);
        border-radius: 15px;
      "
    >
      <div class="row justify-content-center">
        <div style="padding: 0 50px 0">
          <div class="appie-testimonial-slider" style="position: relative">
            <VueSlickCarousel v-bind="settings" ref="carousel">
              <template>
                <div
                  v-for="(sliderContent, index) in sliderContents"
                  :key="index"
                  class="appie-testimonial-item text-center"
                >
                  <!-- <div class="author-info">
                    <img
                      v-if="sliderContent.avatar"
                      :src="sliderContent.avatar"
                      alt=""
                    />
                    <h5 class="title">{{ sliderContent.title }}</h5>
                    <span>{{ sliderContent.date }}</span>
                  </div> -->
                  <div class="text">
                    <p>
                      {{ sliderContent.description }}
                    </p>
                    <ul>
                      <li
                        v-for="(rating, index) in sliderContent.ratings"
                        :key="index"
                      >
                        <i class="fas fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container"
      style="
        display: flex;
        flex-direction: row;
        justify-content: end;
        padding: 20px 0 0;
        max-width: 600px;
      "
    >
      <div
        style="
          width: 100px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <span
          class="prev slick-arrow"
          style="display: block"
          @click.prevent="showNext"
          ><i class="fal fa-arrow-left"></i
        ></span>
        <span
          @click.prevent="showPrev"
          class="next slick-arrow"
          style="display: block"
          ><i class="fal fa-arrow-right"></i
        ></span>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: { VueSlickCarousel },
  props: {
    sliderContents: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      settings: {
        autoplay: true,
        arrows: false,
        dots: true,
      },
    };
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>

<style>
.slick-dots li button:before {
  content: "";
}
.appie-testimonial-slider .slick-arrow.prev {
  left: 20%;
}
.appie-testimonial-slider .slick-arrow {
  top: 50%;
}
</style>
