<template>
  <div>
    <div v-if="showloading">
      <loader-appie />
    </div>
    <div v-else>
      <!--====== PRELOADER PART START ======-->

      <!-- <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>        
        <div class="layer layer-three"><span class="overlay"></span></div>        
    </div> -->

      <!--====== PRELOADER PART ENDS ======-->

      <!--====== OFFCANVAS MENU PART START ======-->

      <sidebar-home-one
        :sidebar="sidebar"
        @toggleSidebar="toggleSidebar"
        :menuItems="navs"
      />

      <!--====== OFFCANVAS MENU PART ENDS ======-->

      <!--====== PART START ======-->

      <header-home-one @toggleSidebar="toggleSidebar" :menuItems="navs" />

      <!--====== PART ENDS ======-->

      <!--====== APPIE HERO PART START ======-->

      <hero-home-one
        slogan="Pioneering Innovation Today, for a "
        sloganTwo="More Sustainable Future"
        description="We focus on Green Technology Solutions to digitize banking ecosystems for underserved cooperatives in Southeast Asia."
        first_button_content="Get Started"
        first_button_icon="fal fa-long-arrow-right"
        second_button_content="Watch Video"
        second_button_icon="far fa-play-circle"
      />

      <!--====== APPIE HERO PART ENDS ======-->

      <!--====== APPIE SERVICES PART START ======-->
      <services-home-one
        :grid_items="itemsGrid"
        section_heading="Designed with Green"
        more_heading="in Mind."
        description="As a tech firm, we collaborate with banking, insurance, local cooperatives, and trading enterprises to modernize their operational workflows through a straightforward, sustainable, and adaptable technology system applicable across diverse setups."
      />
      <!--====== APPIE SERVICES PART ENDS ======-->

      <!--====== APPIE FEATURES PART START ======-->
      <!-- <features-home-one /> -->

      <!--====== APPIE FEATURES PART ENDS ======-->

      <!--====== APPIE TRAFFIC PART START ======-->

      <traffic-home-one
        :traffic_thumbnail="traffic_thumb"
        small_title="Traffic"
        big_title="Scalable Cloud-Based Deployment that Support Small or Large Sized Co-Op."
        description="Get started instantly with our pre-built standard system, while tailor-made customizations are just a few weeks away!"
        :features="features"
      />

      <!--====== APPIE TRAFFIC PART ENDS ======-->

      <!--====== APPIE TESTIMONIAL PART START ======-->

      <!-- <testimonial-neo-home-one :sliderContents="sliderContents" /> -->

      <!-- <testimonial-home-one :sliderContents="sliderContents" /> -->

      <!--====== APPIE TESTIMONIAL PART ENDS ======-->

      <!--====== APPIE TEAM PART START ======-->

      <partner-home-one :partnerContents="partnerContents" />

      <!-- <team-home-one
        title="Meet our Team Members"
        description="Different layouts and styles for team sections."
        :members="members"
      /> -->

      <!--====== APPIE TEAM PART ENDS ======-->

      <!--====== APPIE PRICING PART START ======-->

      <!-- <priceing-home-one
        title="Choose your plan"
        description="Different layouts and styles for team sections."
      /> -->

      <!--====== APPIE PRICING PART ENDS ======-->

      <!--====== APPIE FAQ PART START ======-->

      <!-- <faq-home-one title="Frequently asked questions" description="FAQ" /> -->

      <!--====== APPIE FAQ PART ENDS ======-->

      <!--====== APPIE BLOG PART START ======-->

      <!-- <blog-home-one
        title="Latest blog posts"
        description="Different layouts and styles for team sections."
        :blogs="blogs"
      /> -->

      <!--====== APPIE BLOG PART ENDS ======-->

      <!--====== APPIE PROJECT PART START ======-->

      <project-home-one
        title="Ready to explore your requirements to us?"
        description="Tell us your email and we’ll get connected"
      />

      <!--====== APPIE PROJECT PART ENDS ======-->

      <!--====== APPIE FOOTER PART START ======-->

      <footer-home-one />

      <!--====== APPIE FOOTER PART ENDS ======-->

      <!--====== APPIE BACK TO TOP PART ENDS ======-->
      <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
      </div>
      <!--====== APPIE BACK TO TOP PART ENDS ======-->
    </div>
  </div>
</template>
<script>
import blogOne from "@/assets/images/blog-1.jpg";
import blogTwo from "@/assets/images/blog-2.jpg";
import blogThree from "@/assets/images/blog-3.jpg";
import memberOne from "@/assets/images/team-1.jpg";
import memberTwo from "@/assets/images/team-2.jpg";
import memberThree from "@/assets/images/team-3.jpg";
import memberFour from "@/assets/images/team-4.jpg";
import testmonialAvater from "@/assets/images/testimonial-user-1.svg";
import testmonialAvaterTwo from "@/assets/images/testimonial-user-2.svg";
import traffic_thumb from "@/assets/images/traffic_neo.svg";
import icon_one from "@/assets/images/coop.png";
import icon_two from "@/assets/images/supply.png";
import icon_three from "@/assets/images/electric.png";
import icon_four from "@/assets/images/technology.png";
import feat_one from "@/assets/images/flexible.png";
import feat_two from "@/assets/images/secure.png";
import feat_three from "@/assets/images/support.png";
import feat_four from "@/assets/images/user.png";
import HeaderHomeOne from "./HeaderHomeOne.vue";
import SidebarHomeOne from "./SidebarHomeOne.vue";
import HeroHomeOne from "./HeroHomeOne.vue";
import ServicesHomeOne from "./ServicesHomeOne.vue";
// import FeaturesHomeOne from "./FeaturesHomeOne.vue";
import TrafficHomeOne from "./TrafficHomeOne.vue";
// import TestimonialNeoHomeOne from "./TestimonialNeoHomeOne.vue";
import PartnerHomeOne from "./PartnerHomeOne.vue";
// import TestimonialHomeOne from "./TestimonialHomeOne.vue";
// import TeamHomeOne from "./TeamHomeOne.vue";
// import PriceingHomeOne from "./PriceingHomeOne.vue";
// import FaqHomeOne from "./FaqHomeOne.vue";
// import BlogHomeOne from "./BlogHomeOne.vue";
import ProjectHomeOne from "./ProjectHomeOne.vue";
import FooterHomeOne from "./FooterHomeOne.vue";
import LoaderAppie from "../LoaderAppie.vue";
import japfa from "@/assets/images/japfa.png";
import cimory from "@/assets/images/cimory.png";
import sederhana from "@/assets/images/sederhana.png";
import sariratu from "@/assets/images/sariratu.png";
import dapursolo from "@/assets/images/dapursolo.png";
import bumbudesa from "@/assets/images/bumbudesa.png";
import wongsolo from "@/assets/images/wongsolo.png";
import indoguna from "@/assets/images/indoguna.png";
import rempahwangi from "@/assets/images/rempahwangi.png";
import laksana from "@/assets/images/laksana.png";
import rempah from "@/assets/images/rempah.png";
import rajarasa from "@/assets/images/rajarasa.png";
import sambara from "@/assets/images/sambara.png";
import dapursunda from "@/assets/images/dapursunda.png";
import porto from "@/assets/images/porto.png";
import dharmajaya from "@/assets/images/dharmajaya.png";
import berdikari from "@/assets/images/berdikari.png";
import indocater from "@/assets/images/indocater.png";
import psu from "@/assets/images/psu.png";
import m from "@/assets/images/m.png";
import spaa from "@/assets/images/spaa.png";
import bss from "@/assets/images/bss.png";
import nal from "@/assets/images/nal.png";
import bima from "@/assets/images/bima.png";
import amt from "@/assets/images/amt.png";

export default {
  name: "HomeOne",
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderHomeOne,
    SidebarHomeOne,
    HeroHomeOne,
    ServicesHomeOne,
    // FeaturesHomeOne,
    TrafficHomeOne,
    // TestimonialNeoHomeOne,
    PartnerHomeOne,
    // TestimonialHomeOne,
    // TeamHomeOne,
    // PriceingHomeOne,
    // FaqHomeOne,
    // BlogHomeOne,
    ProjectHomeOne,
    FooterHomeOne,
    LoaderAppie,
  },
  data() {
    return {
      traffic_thumb: traffic_thumb,
      testmonialAvater: testmonialAvater,
      sidebar: false,
      showloading: true,
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Solutions",
          childrens: [
            {
              name: "Verde Co-op",
              path: "/co-op",
            },
            {
              name: "Verde SCF",
              path: "/scf",
            },
            // {
            //   name: "Verde Power",
            //   path: "/power",
            // },
            {
              name: "Verde Tech",
              path: "/tech",
            },
          ],
        },
        {
          name: "About Us",
          path: "/about-us",
        },
      ],
      itemsGrid: [
        {
          icon: icon_one,
          title: "Verde Co-Op",
          content:
            "A comprehensive end-to-end Cooperative system, tailored to the unique needs of each Cooperative.",
          customClass: "",
        },
        {
          icon: icon_two,
          title: "Verde Supply Chain Financing",
          content:
            "Our platform simplifies finance for agriculture and food businesses, offering easy setup, invoice financing, and management of inventory and orders.",
          customClass: "item-2",
        },
        {
          icon: icon_three,
          title: "Verde Electric",
          content:
            "We exclusively distribute green products such as e-motorcycles, sustainable battery cells & automobile parts in Southeast Asia.",
          customClass: "item-3",
        },
        {
          icon: icon_four,
          title: "Verde Technology",
          content:
            "As a tech company, we offer bespoke technology solutions to the banking / finance industries in the form of whitelisting our products.",
          customClass: "item-4",
        },
      ],
      features: [
        {
          title: "Flexible Pricing",
          img: feat_one,
          bg: "", // create class for style
          description:
            "Grow your system incrementally. Freely choose monthly, annual, or opt for a per-transaction model.",
        },
        {
          title: "Dedicated Team Support",
          img: feat_three,
          bg: "item-2", // create class for style
          description:
            "Peace of mind when it comes to troubleshooting and support.",
        },
        {
          title: "Secure and Sandboxed",
          img: feat_two,
          bg: "item-3", // create class for style
          description:
            "Worldclass enterprise grade encryption with MFA embedded.",
        },
        {
          title: "User Friendly",
          img: feat_four,
          bg: "item-4", // create class for style
          description:
            "Easy to use and intuitive to pick up even for non-tech people.",
        },
      ],
      sliderContents: [
        {
          title: "Bambang Pacul",
          date: "Chief of bSS Cooperatives",
          description:
            "I've been using this platform for the past six months, and it has completely transformed the way I manage my tasks. The user-friendly interface and customizable features make it a breeze to stay organized. Highly recommend it to every Koperasi if looking for an efficient way to keep track of their daily activities!",
          ratings: 5,
          avatar: testmonialAvater,
        },
        {
          title: "Intan Lestari",
          date: "Legal Officer",
          description:
            "As a small business owner, this platform has been a game-changer for me. It's helped me streamline my operations and communicate more effectively with my team. The customer support is also top-notch – always responsive and helpful. Five stars all the way!",
          ratings: 5,
          avatar: testmonialAvaterTwo,
        },
      ],
      partnerContents: [
        {
          avatar: japfa,
        },
        {
          avatar: cimory,
        },
        {
          avatar: sederhana,
        },
        {
          avatar: sariratu,
        },
        {
          avatar: dapursolo,
        },
        {
          avatar: bumbudesa,
        },
        {
          avatar: wongsolo,
        },
        {
          avatar: indoguna,
        },
        {
          avatar: rempahwangi,
        },
        {
          avatar: laksana,
        },
        {
          avatar: rempah,
        },
        {
          avatar: rajarasa,
        },
        {
          avatar: sambara,
        },
        {
          avatar: dapursunda,
        },
        {
          avatar: porto,
        },
        {
          avatar: dharmajaya,
        },
        {
          avatar: berdikari,
        },
        {
          avatar: indocater,
        },
        {
          avatar: psu,
        },
        {
          avatar: m,
        },
        {
          avatar: spaa,
        },
        {
          avatar: bss,
        },
        {
          avatar: nal,
        },
        {
          avatar: bima,
        },
        {
          avatar: amt,
        },
      ],
      memberOne: memberOne,
      memberTwo: memberTwo,
      memberThree: memberThree,
      memberFour: memberFour,
      members: [
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberTwo,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://www.twitter.com",
              icon: "fab fa-twitter", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberOne,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://www.twitter.com",
              icon: "fab fa-twitter", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberThree,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
        {
          name: "Benjamin Evalent",
          position: "CEO-Founder",
          img: memberFour,
          socials: [
            {
              name: "facebok",
              url: "https://www.facebook.com",
              icon: "fab fa-facebook-f", //user font awesome class
            },
            {
              name: "twitter",
              url: "https://www.twitter.com",
              icon: "fab fa-twitter", //user font awesome class
            },
            {
              name: "pinterest",
              url: "https://www.pinterest.com/",
              icon: "fab fa-pinterest-p", //user font awesome class
            },
          ],
        },
      ],
      blogOne: blogOne,
      blogTwo: blogTwo,
      blogThree: blogThree,
      blogs: [
        {
          img: blogOne,
          date: "March 10, 2022",
          category: "Saas & App",
          title: "Introducing New Apps Design for our iOS App",
        },
        {
          img: blogTwo,
          date: "March 10, 2022",
          category: "Saas & App",
          title: "How to bring Dolce to your company",
        },
        {
          img: blogThree,
          date: "March 10, 2022",
          category: "Saas & App",
          title: "17 Effective Ways to Monetize Mobile Apps",
        },
      ],
    };
  },
  created() {
    if (!this.rtl) {
      this.preLoading();
    } else {
      this.showloading = false;
    }
    document.addEventListener("scroll", this.topToBottom);
  },

  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
      }, 2000);
    },
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>
