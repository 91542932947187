<template>
  <!--====== APPIE SERVICES PART START ======-->

  <section class="appie-service-area pt-90 pb-100" id="service">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">
              {{ section_heading }}
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center"
      style="background-color: #01512a"
    >
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/customizable.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
      <div
        class="d-flex flex-col align-items-center"
        style="max-width: 600px; padding-left: 30px"
      >
        <div class="appie-traffic-title">
          <h3 class="title" style="color: white">Customizable</h3>
          <p style="color: white; margin-top: 30px">
            Tailor the system to align with the specifics of your cooperative
            business. Enjoy the freedom to select according to your preferences.
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center"
      style="padding: 20px 0 20px"
    >
      <div class="d-flex flex-col align-items-center" style="max-width: 600px">
        <div class="appie-traffic-title">
          <h3 class="title">Scalable</h3>
          <p style="margin-top: 30px">
            We utilize a cloud-based deployment approach that is conducive to
            multiple tenants, ensuring scalability for unlimited growth.
          </p>
        </div>
      </div>
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/scalable.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center"
      style="background-color: #01512a; padding: 20px 0 20px"
    >
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/security.png"
          alt=""
          style="max-width: 400px"
        />
      </div>
      <div
        class="d-flex flex-col align-items-center"
        style="max-width: 600px; padding-left: 30px"
      >
        <div class="appie-traffic-title">
          <h3 class="title" style="color: white">Secure</h3>
          <p style="color: white; margin-top: 30px">
            Ensuring system security is our utmost priority. We employ sandboxed
            environments to mitigate the risk of cross-data leaks and potential
            hacks.
          </p>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-row justify-content-center"
      style="padding: 20px 0 0; margin-bottom: -50px"
    >
      <div class="d-flex flex-col align-items-center" style="max-width: 600px">
        <div class="appie-traffic-title">
          <h3 class="title">Flexible Pricing</h3>
          <p style="margin-top: 30px">
            You don't have to be concerned about pricing. We adapt our rates to
            fit within your budget. We offer flexible options such as monthly or
            member-based pricing tailored to your business needs.
          </p>
        </div>
      </div>
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/flexibly.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
    </div>
  </section>

  <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {
  props: {
    section_heading: {
      type: String,
    },
    more_heading: {
      type: String,
    },
    description: {
      type: String,
    },
    grid_items: {
      type: Array,
    },
  },
};
</script>

<style></style>
