<template>
  <contact-us />
</template>

<script>
import ContactUs from "../components/ContactUs/index";
export default {
  components: { ContactUs },
};
</script>

<style></style>
