<template>
  <tech />
</template>

<script>
import Tech from "../components/Tech/index";
export default {
  components: { Tech },
};
</script>

<style></style>
