<template>
  <scf />
</template>

<script>
import Scf from "../components/Scf/index";
export default {
  components: { Scf },
};
</script>

<style></style>
