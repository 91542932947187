var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"appie-faq-area pt-40 pb-95"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"appie-section-title"},[_c('p',[_vm._v(_vm._s(_vm.description))]),_c('h3',{staticClass:"appie-title"},[_vm._v(_vm._s(_vm.title))])])])]),_c('div',{staticClass:"row",staticStyle:{"padding-top":"30px"}},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"faq-accordion wow fadeInRight mt-30",attrs:{"data-wow-duration":"1500ms"}},[_c('div',{staticClass:"accrodion-grp animated fadeIn faq-accrodion wow",attrs:{"data-wow-duration":"1500ms","data-grp-name":"faq-accrodion"}},[_c('div',{staticClass:"accrodion",class:[_vm.showQuestion ? 'active' : '']},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(0),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion ? { display: 'block' } : { display: 'none' },
                  ])},[_vm._m(1)])])]),_c('div',{staticClass:"accrodion",on:{"click":function($event){$event.preventDefault();return _vm.OpenQuestion(2)}}},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(2),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion ? { display: 'block' } : { display: 'none' },
                  ])},[_vm._m(3)])])])])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"faq-accordion wow fadeInRight mt-30",attrs:{"data-wow-duration":"1500ms"}},[_c('div',{staticClass:"accrodion-grp animated fadeIn faq-accrodion wow",attrs:{"data-wow-duration":"1500ms","data-grp-name":"faq-accrodion"}},[_c('div',{staticClass:"accrodion",on:{"click":function($event){$event.preventDefault();return _vm.OpenQuestion(3)}}},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(4),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion ? { display: 'block' } : { display: 'none' },
                  ])},[_vm._m(5)])])]),_c('div',{staticClass:"accrodion",class:[_vm.showQuestion ? 'active' : ''],on:{"click":function($event){$event.preventDefault();return _vm.OpenQuestion(4)}}},[_c('div',{staticClass:"accrodion-inner"},[_vm._m(6),_c('div',{staticClass:"accrodion-content",style:([
                    _vm.showQuestion ? { display: 'block' } : { display: 'none' },
                  ])},[_vm._m(7)])])])])])]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',{staticStyle:{"color":"white"}},[_vm._v(" Where I can find out about pricing information for Verde Co-Op? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',{staticStyle:{"color":"white"}},[_vm._v(" Please drop us an email at contact@verdekinetics.com with your requirements, location & concat details. We’ll get back to you as soon as possible. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',[_vm._v("What is your after sales support like?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',[_vm._v(" We have a dedicated support team on standby, please use our chat function should you want an immediate follow up on your enquiry. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',[_vm._v(" What technology solutions are you able to develop for my company? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',[_vm._v(" We specialize in the finance industries (insurance, banking, supply chain financing) technology. We’re can develop a new fullstack application as the middleware for you. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accrodion-title"},[_c('h4',{staticStyle:{"color":"white"}},[_vm._v(" I am interested to find out more info on your Tesla Batteries. How do get to know more? ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner"},[_c('p',{staticStyle:{"color":"white"}},[_vm._v(" Naff Oxford vagabond in my flat chinwag blatant grub tomfoolery that I bits and bobs up the duff cras boot bevvy no biggie. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"faq-text text-center pt-40"},[_c('p',[_vm._v(" Can't find an answer? "),_c('a',{attrs:{"href":"mailto:contact@verdekinetics.com"}},[_vm._v("Email us")])])])])
}]

export { render, staticRenderFns }