<template>
  <about-us />
</template>

<script>
import AboutUs from "../components/About/index";
export default {
  components: {
    AboutUs,
  },
};
</script>

<style></style>
