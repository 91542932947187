<template>
  <!--====== APPIE SERVICES PART START ======-->

  <section class="appie-service-area pt-90" id="service">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-row justify-content-center" style="width: 100%">
        <h1 class="title">Our Vision</h1>
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/rocket.png"
          alt=""
          style="max-width: 100px; margin: -50px 0 0"
        />
      </div>
      <div
        class="d-flex flex-col align-items-center"
        style="
          max-width: 1000px;
          padding-left: 100px;
          border-left: 1px solid #b0b0b0;
        "
      >
        <div class="appie-traffic-title">
          <ul style="margin-top: 30px; list-style-type: disc">
            <li style="font-size: 22px; line-height: 1.5">
              We Use Advancements in Technology to Create Synergy Across
              Multiple Industries to Foster a Greener Tomorrow.
            </li>
            <li style="font-size: 22px; margin-top: 20px; line-height: 1.5">
              Driving Today’s Innovation toward a Greener Tomorrow.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {
  props: {
    section_heading: {
      type: String,
    },
    more_heading: {
      type: String,
    },
    description: {
      type: String,
    },
    grid_items: {
      type: Array,
    },
  },
};
</script>

<style></style>
