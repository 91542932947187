<template>
  <power />
</template>

<script>
import Power from "../components/Power/index";
export default {
  components: { Power },
};
</script>

<style></style>
