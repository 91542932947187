<template>
  <div>
    <div v-if="showloading">
      <loader-appie />
    </div>
    <div v-else>
      <!--====== PRELOADER PART START ======-->

      <!-- <div class="loader-wrap">
          <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
          <div class="layer layer-one"><span class="overlay"></span></div>
          <div class="layer layer-two"><span class="overlay"></span></div>        
          <div class="layer layer-three"><span class="overlay"></span></div>        
      </div> -->

      <!--====== PRELOADER PART ENDS ======-->

      <!--====== OFFCANVAS MENU PART START ======-->

      <sidebar-home-one
        :sidebar="sidebar"
        @toggleSidebar="toggleSidebar"
        :menuItems="navs"
      />

      <!--====== OFFCANVAS MENU PART ENDS ======-->

      <!--====== PART START ======-->

      <header-home-one @toggleSidebar="toggleSidebar" :menuItems="navs" />

      <!--====== PART ENDS ======-->

      <!--====== APPIE HERO PART START ======-->

      <hero-home-one
        slogan="Plug & play our financing module in no time."
        description="Instantly launch your own supply chain financing network to invite buyers & sellers into your SCF platform."
        first_button_content="Get Started"
        first_button_icon="fal fa-long-arrow-right"
        second_button_content="Watch Video"
        second_button_icon="far fa-play-circle"
      />

      <!--====== APPIE HERO PART ENDS ======-->

      <!--====== APPIE SERVICES PART START ======-->

      <services-home-one section_heading="Why Use Verde?" />

      <!--====== APPIE SERVICES PART ENDS ======-->

      <benefits
        :grid_items="itemsGrid"
        section_heading="Why Use Verde?"
        more_heading="in Mind."
        description="As a technology company, we work with banking, insurance, local cooperatives and the trading businesses to digitize their current work processes by providing a technology system that is simple, sustainable and flexible to implement in all kinds of set up."
      />

      <!--====== APPIE FOOTER PART START ======-->

      <footer-home-one />

      <!--====== APPIE FOOTER PART ENDS ======-->

      <!--====== APPIE BACK TO TOP PART ENDS ======-->
      <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
      </div>
      <!--====== APPIE BACK TO TOP PART ENDS ======-->
    </div>
  </div>
</template>
<script>
import testmonialAvater from "@/assets/images/testimonial-user.png";
import traffic_thumb from "@/assets/images/traffic.png";
import HeaderHomeOne from "./HeaderHomeOne.vue";
import SidebarHomeOne from "./SidebarHomeOne.vue";
import HeroHomeOne from "./HeroHomeOne.vue";
import FooterHomeOne from "./FooterHomeOne.vue";
import ServicesHomeOne from "./ServicesHomeOne.vue";
import Benefits from "./Benefits.vue";
import LoaderAppie from "../LoaderAppie.vue";
import icon_one from "@/assets/images/secure_icon.png";
import icon_two from "@/assets/images/flexible_icon.png";
import icon_three from "@/assets/images/cloud_icon.png";

export default {
  name: "HomeOne",
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderHomeOne,
    SidebarHomeOne,
    HeroHomeOne,
    FooterHomeOne,
    LoaderAppie,
    ServicesHomeOne,
    Benefits,
  },
  data() {
    return {
      traffic_thumb: traffic_thumb,
      testmonialAvater: testmonialAvater,
      sidebar: false,
      showloading: true,
      navs: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Solutions",
          childrens: [
            {
              name: "Verde Co-op",
              path: "/co-op",
            },
            {
              name: "Verde SCF",
              path: "/scf",
            },
            // {
            //   name: "Verde Power",
            //   path: "/power",
            // },
            {
              name: "Verde Tech",
              path: "/tech",
            },
          ],
        },
        {
          name: "About Us",
          path: "/about-us",
        },
      ],
      itemsGrid: [
        {
          icon: icon_one,
          title: "Secure",
          content:
            "We utilize a sandboxed environment to mitigate the risk of data breaches and unauthorized access by hackers.",
          customClass: "",
        },
        {
          icon: icon_two,
          title: "Flexible Price",
          content:
            "Pricing options are available on a monthly basis or tailored to membership, ensuring flexibility to accommodate your specific circumstances.",
          customClass: "item-2",
        },
        {
          icon: icon_three,
          title: "Scalable",
          content:
            "Experience scalability like never before with our cloud-based technology solutions.",
          customClass: "item-2",
        },
      ],
    };
  },
  created() {
    if (!this.rtl) {
      this.preLoading();
    } else {
      this.showloading = false;
    }
    document.addEventListener("scroll", this.topToBottom);
  },

  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
      }, 2000);
    },
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style></style>
