<template>
  <section
    class="appie-testimonial-area partner-hero pb-100"
    id="testimonial"
    style="margin-top: 100px; margin-bottom: 100px"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="title">Our Partners</div>
          <div style="display: flex; flex-wrap: wrap; padding-top: 30px">
            <div
              v-for="(partnerContent, index) in partnerContents"
              :key="index"
              style="flex: 20%"
            >
              <img
                v-if="partnerContent.avatar"
                :src="partnerContent.avatar"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    partnerContents: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style>
.slick-dots li button:before {
  content: "";
}
.appie-testimonial-slider .slick-arrow.prev {
  left: 20%;
}
.appie-testimonial-slider .slick-arrow {
  top: 50%;
}
.title {
  font-weight: 700;
  font-size: 35px;
  font-family: "Exo 2", sans-serif;
  color: #0e1133;
  margin: 0px;
}
.whiteText {
  color: white;
}
.sliderFirst {
  background-color: #01512a;
}
.sliderRest {
  background-color: #f8fff9;
}
.partner-hero {
  background-image: url(../../assets/images/partner-bg.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
