<template>
  <coop />
</template>

<script>
import Coop from "../components/Coop/index";
export default {
  components: { Coop },
};
</script>

<style></style>
