<template>
  <!--====== APPIE SERVICES PART START ======-->

  <section class="appie-service-area pt-90" id="service">
    <div class="d-flex flex-row justify-content-between">
      <div class="traffic-thumb">
        <img
          class="wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="200ms"
          src="@/assets/images/ocr.png"
          alt=""
          style="max-width: 500px"
        />
      </div>
      <div
        class="d-flex flex-col align-items-center"
        style="max-width: 800px; padding-left: 30px"
      >
        <div class="appie-traffic-title">
          <h3 class="title">
            Utilization of OCR Technology in the Various Industries:
          </h3>
          <div style="margin-top: 30px">
            <p style="font-size: 22px">1. Insurance.</p>
            <p style="font-size: 22px; margin-top: 20px">2. Multi-finance.</p>
            <p style="font-size: 22px; margin-top: 20px">
              3. Traffic police for processing claims.
            </p>
            <p style="font-size: 22px; margin-top: 20px">
              4. Hardcopy of vehicle registration books to convert
              automatically.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {
  props: {
    section_heading: {
      type: String,
    },
    more_heading: {
      type: String,
    },
    description: {
      type: String,
    },
    grid_items: {
      type: Array,
    },
  },
};
</script>

<style></style>
