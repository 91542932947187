<template>
  <!--====== APPIE FAQ PART START ======-->

  <section class="appie-faq-area pt-40 pb-95">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="appie-section-title">
            <p>{{ description }}</p>
            <h3 class="appie-title">{{ title }}</h3>
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 30px">
        <div class="col-lg-6">
          <div
            class="faq-accordion wow fadeInRight mt-30"
            data-wow-duration="1500ms"
          >
            <div
              class="accrodion-grp animated fadeIn faq-accrodion wow"
              data-wow-duration="1500ms"
              data-grp-name="faq-accrodion"
            >
              <div class="accrodion" :class="[showQuestion ? 'active' : '']">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4 style="color: white">
                      Where I can find out about pricing information for Verde
                      Co-Op?
                    </h4>
                  </div>
                  <div
                    class="accrodion-content"
                    :style="[
                      showQuestion ? { display: 'block' } : { display: 'none' },
                    ]"
                  >
                    <div class="inner">
                      <p style="color: white">
                        Please drop us an email at contact@verdekinetics.com
                        with your requirements, location & concat details. We’ll
                        get back to you as soon as possible.
                      </p>
                    </div>
                    <!-- /.inner -->
                  </div>
                </div>
                <!-- /.accrodion-inner -->
              </div>
              <div class="accrodion" @click.prevent="OpenQuestion(2)">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4>What is your after sales support like?</h4>
                  </div>
                  <div
                    class="accrodion-content"
                    :style="[
                      showQuestion ? { display: 'block' } : { display: 'none' },
                    ]"
                  >
                    <div class="inner">
                      <p>
                        We have a dedicated support team on standby, please use
                        our chat function should you want an immediate follow up
                        on your enquiry.
                      </p>
                    </div>
                    <!-- /.inner -->
                  </div>
                </div>
                <!-- /.accrodion-inner -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="faq-accordion wow fadeInRight mt-30"
            data-wow-duration="1500ms"
          >
            <div
              class="accrodion-grp animated fadeIn faq-accrodion wow"
              data-wow-duration="1500ms"
              data-grp-name="faq-accrodion"
            >
              <div class="accrodion" @click.prevent="OpenQuestion(3)">
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4>
                      What technology solutions are you able to develop for my
                      company?
                    </h4>
                  </div>
                  <div
                    class="accrodion-content"
                    :style="[
                      showQuestion ? { display: 'block' } : { display: 'none' },
                    ]"
                  >
                    <div class="inner">
                      <p>
                        We specialize in the finance industries (insurance,
                        banking, supply chain financing) technology. We’re can
                        develop a new fullstack application as the middleware
                        for you.
                      </p>
                    </div>
                    <!-- /.inner -->
                  </div>
                </div>
                <!-- /.accrodion-inner -->
              </div>
              <div
                class="accrodion"
                :class="[showQuestion ? 'active' : '']"
                @click.prevent="OpenQuestion(4)"
              >
                <div class="accrodion-inner">
                  <div class="accrodion-title">
                    <h4 style="color: white">
                      I am interested to find out more info on your Tesla
                      Batteries. How do get to know more?
                    </h4>
                  </div>
                  <div
                    class="accrodion-content"
                    :style="[
                      showQuestion ? { display: 'block' } : { display: 'none' },
                    ]"
                  >
                    <div class="inner">
                      <p style="color: white">
                        Naff Oxford vagabond in my flat chinwag blatant grub
                        tomfoolery that I bits and bobs up the duff cras boot
                        bevvy no biggie.
                      </p>
                    </div>
                    <!-- /.inner -->
                  </div>
                </div>
                <!-- /.accrodion-inner -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="faq-text text-center pt-40">
            <p>
              Can't find an answer?
              <a href="mailto:contact@verdekinetics.com">Email us</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FAQ PART ENDS ======-->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  data() {
    return {
      showQuestion: 1,
    };
  },
  methods: {
    OpenQuestion(value) {
      this.showQuestion = value;
    },
  },
};
</script>

<style></style>
