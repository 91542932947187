<template>
  <!--====== APPIE TRAFFIC PART START ======-->

  <section class="appie-traffic-area pb-200">
    <div class="traffic-thumb">
      <img
        class="wow animated fadeInRight"
        data-wow-duration="2000ms"
        data-wow-delay="200ms"
        :src="traffic_thumbnail"
        alt=""
      />
    </div>
    <div class="col-md-12 col-lg-6">
      <div class="appie-traffic-title">
        <h3 class="title">{{ big_title }}</h3>
        <p>{{ description }}</p>
      </div>
      <div class="row">
        <div
          class="col-lg-6 col-md-6"
          v-for="(feature, index) in features"
          :key="index"
        >
          <div
            class="appie-traffic-service mb-30"
            :class="[feature.bg && feature.bg !== '' ? feature.bg : '']"
          >
            <div class="icon">
              <!-- <i class="fal fa-check"></i> -->
              <img
                class="wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
                :src="feature.img"
                alt=""
              />
            </div>
            <div style="margin-left: 40px">
              <h5 class="title">{{ feature.title }}</h5>
              <p>{{ feature.description }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-12">
          <div class="traffic-btn mt-50">
            <router-link class="main-btn" to="/about-us"
              >Learn More <i class="fal fa-arrow-right"></i
            ></router-link>
          </div>
        </div> -->
      </div>
    </div>
  </section>

  <!--====== APPIE TRAFFIC PART ENDS ======-->
</template>

<script>
export default {
  props: {
    traffic_thumbnail: {
      type: String,
    },
    small_title: {
      type: String,
    },
    big_title: {
      type: String,
    },
    description: {
      type: String,
    },
    features: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style></style>
