<template>
  <!--====== APPIE HERO PART START ======-->
  <section class="appie-hero-area-second">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12" style="text-align: center">
          <div class="appie-hero-content">
            <span>{{ welcome_message }}</span>
            <h1 class="appie-title">{{ slogan }}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--====== APPIE HERO PART end ======-->
</template>

<script>
export default {
  props: [
    "welcome_message",
    "slogan",
    "description",
    "thumb_one",
    "thumb_two",
    "first_button_content",
    "first_button_icon",
    "second_button_content",
    "second_button_icon",
  ],
};
</script>

<style></style>
