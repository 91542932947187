<template>
  <!--====== APPIE SERVICES PART START ======-->

  <section class="appie-service-area pt-90 pb-100" id="service">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="appie-section-title text-center">
            <h3 class="appie-title">
              {{ section_heading }}
            </h3>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-around">
        <ul style="margin-top: 30px; list-style-type: disc">
          <li style="font-size: 22px; line-height: 1.5">
            The word “Verde” derives from the Italian language as green, while
            Kinetics is the engine for constant motion in innovation
          </li>
          <li style="font-size: 22px; margin-top: 20px; line-height: 1.5">
            Established in 2022, PT. Verde Kinetics is a green financial service
            technology ecosystem to the undeserved communities in Indonesia as
            well as in the SEA region.
          </li>
          <li style="font-size: 22px; margin-top: 20px; line-height: 1.5">
            The main offerings comprise financial systems crafted for
            cooperatives to transition to digital operations and secure funding
            for managing their own supply chain financing requirements,
            facilitating the expansion of their cooperatives.
          </li>
          <li style="font-size: 22px; margin-top: 20px; line-height: 1.5">
            We also distribute green vehicles like e-motorcycles and
            four-wheeled automobile to promote a greener environment.
          </li>
          <li style="font-size: 22px; margin-top: 20px; line-height: 1.5">
            Additionally, we have obtained our Cooperative license in Indonesia,
            enabling us to administer our own trade loan initiatives for SMEs
            and Micro-SMEs with the aim of boosting their trade activity.
          </li>
        </ul>
      </div>
    </div>
  </section>

  <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {
  props: {
    section_heading: {
      type: String,
    },
    more_heading: {
      type: String,
    },
    description: {
      type: String,
    },
    grid_items: {
      type: Array,
    },
  },
};
</script>

<style></style>
