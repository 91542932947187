<template>
  <!--====== APPIE FOOTER PART START ======-->

  <section class="appie-footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="footer-about-widget">
            <div class="logo">
              <a href="#"
                ><img
                  src="@/assets/images/logo.png"
                  alt=""
                  style="max-width: 200px"
              /></a>
            </div>
            <p>Driving today’s innovation, towards a greener tomorrow</p>

            <div class="footer-widget-info">
              <ul>
                <li>
                  <a href="#"
                    ><i class="fas fa-clock" style="margin-top: 4px"></i>
                    <div>
                      <div>Office Hours</div>
                      <div>Monday-Friday 9 AM-5 PM WIB</div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#"
                    ><i class="fas fa-user-headset" style="margin-top: 4px"></i>
                    <div>
                      <div>Support Hours</div>
                      <div>24/7 365</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-widget-info">
            <h4 class="title">Contact Info</h4>
            <ul>
              <li>
                <a href="#"
                  ><i class="fas fa-envelope" style="margin-top: 4px"></i>
                  contact@verdekinetics.com</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="fas fa-phone" style="margin-top: 4px"></i> +62 (21)
                  50880128</a
                >
              </li>
              <li>
                <a href="#"
                  ><i class="fas fa-city" style="margin-top: 4px"></i> Go-Work
                  Fatmawati, Jl. RS. Fatmawati Raya No.188 3, Jakarta 12420</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Company</h4>
            <ul>
              <li><router-link to="/">Verde Co-op</router-link></li>
              <li>
                <router-link to="/">Verde Supply Chain Financing</router-link>
              </li>
              <li><router-link to="/tech">Verde Technology</router-link></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-navigation">
            <h4 class="title">Our Locations</h4>
            <img
              src="@/assets/images/map.png"
              alt=""
              style="min-width: 350px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div
          class="footer-copyright d-flex align-items-center justify-content-center"
        >
          <div class="copyright-text">
            <p>Copyright © 2024 Verde Kinetics - All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {};
</script>

<style></style>
